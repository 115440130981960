import { Box } from "@mui/material";

export default function WavePanel() {
  return (
    <Box
      sx={{
        zIndex: 2,
        mt: -10,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        height: 160,
        minWidth: 800,

        backgroundImage: 'url("/static/images/wavyPattern.svg")',
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        overflowX: "hidden",
      }}
    />
  );
}
