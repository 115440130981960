// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import NextMuiLink from "blocks/atoms/NextMuiLink";
import checkmarkFill from "@iconify/icons-eva/checkmark-fill";
// material
import { styled } from "@mui/material/styles";
import {
  Card,
  Button,
  Typography,
  Box,
  Stack,
  Link,
  Grid,
  Switch,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "routes/paths";
//
import Label from "blocks/atoms/uicomponents/Label";
import {
  PlanFreeIcon,
  PlanStarterIcon,
  PlanPremiumIcon,
} from "blocks/atoms/graphics";
import { MotionInView, varFade } from "blocks/atoms/uieffects/animate";
import PricingPlanCard from "blocks/views/LandingPage/foundations/pricing/PricingPlanCard";

// ----------------------------------------------------------------------

const PLANS = [
  {
    subscription: "starter",
    icon: <PlanFreeIcon />,
    price: 29.99,
    caption: "1-10 users",
    lists: [
      { text: "Upto 10 Suite members", isAvailable: true },
      { text: "1000 hours team Videocalls", isAvailable: true },
      { text: "250 GB Secure Data vault", isAvailable: true },
      {
        text: "Unlimited Resolutions, Tasks, Meetings, Surveys",
        isAvailable: true,
      },
      { text: "Recording", isAvailable: false },
      { text: "Transcription & Search", isAvailable: false },
      { text: "2-factor Authentication", isAvailable: false },
    ],
    labelAction: "Start Now",
  },
  {
    subscription: "medium",
    icon: <PlanStarterIcon />,
    price: 69.99,
    caption: "for moderate usecase",
    lists: [
      { text: "Upto 25 Suite members", isAvailable: true },
      { text: "5000 hours team Videocalls", isAvailable: true },
      { text: "1 TB Secure Data vault", isAvailable: true },
      {
        text: "Unlimited Resolutions, Tasks, Meetings, Surveys",
        isAvailable: true,
      },
      { text: "Recording", isAvailable: true },
      { text: "Transcription & Search", isAvailable: true },
      { text: "2-factor Authentication", isAvailable: false },
    ],
    labelAction: "choose medium",
  },
  {
    subscription: "enterprise",
    icon: <PlanPremiumIcon />,
    price: 199.99,
    caption: "for large enterprises",
    lists: [
      { text: "Upto 100 Suite members", isAvailable: true },
      { text: "Unlimited team Videocalls", isAvailable: true },
      { text: "10 TB Secure Data vault", isAvailable: true },
      {
        text: "Unlimited Resolutions, Tasks, Meetings, Surveys",
        isAvailable: true,
      },
      { text: "Recording", isAvailable: true },
      { text: "Transcription & Search", isAvailable: true },
      { text: "2FA & Additional Security", isAvailable: true },
    ],
    labelAction: "choose premium",
  },
];

const RootStyle = styled(Box)(({ theme }) => ({
  margin: "auto",
  display: "flex",
  position: "relative",
  alignItems: "center",
  width: "100%",
  flexDirection: "column",
  padding: theme.spacing(2),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(3),
  },
}));

export default function DataSuitePricing() {
  function animateAction(index) {
    if (index % 3 === 0) {
      return varFade().inLeft;
    } else if (index % 3 === 2) {
      return varFade().inRight;
    } else return varFade().in;
  }

  return (
    <RootStyle elevation={0}>
      {/*<Box sx={{ my: 5 }}>
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Typography variant="overline" sx={{ mr: 1.5 }}>
            MONTHLY
          </Typography>
          <Switch />
          <Typography variant="overline" sx={{ ml: 1.5 }}>
            YEARLY (save 10%)
          </Typography>
        </Stack>
        <Typography variant="caption" align="right" sx={{ color: 'text.secondary', display: 'block' }}>
          * Plus applicable taxes
  </Typography>
      </Box>*/}

      <Grid container spacing={3}>
        {PLANS.map((card, index) => (
          <Grid item xs={12} md={4} key={card.subscription}>
            <MotionInView variants={animateAction(index)}>
              <PricingPlanCard card={card} index={index} />
            </MotionInView>
          </Grid>
        ))}
      </Grid>
    </RootStyle>
  );
}
