// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { m } from "framer-motion";
import { useState, useRef } from "react";
// @mui
import { alpha, useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  CardMedia,
  Paper,
  Button,
  Typography,
  CardContent,
} from "@mui/material";
import { NextMuiLink } from "blocks/atoms";
// _mock_

// components
import { LazyImage } from "blocks/atoms";
import { MotionContainer, varFade } from "blocks/atoms/uieffects/animate";
import { CarouselArrowIndex } from "blocks/atoms/uicomponents/carousel";
import { mockImgLandingPage } from "blocks/atoms/graphics/mockImages";
// ----------------------------------------------------------------------
const DESCRIPTIONS = [
  "Built to Suit for your Boardroom Conversations. Conduct your Boardroom Meetings online with ease. Share confidential information with ease.  Support for tabling resolutions and recording Director Voting, Automated Meeting Minutes generation, Scheduling, Action Items tracking and much more .",
  "Need a Dataroom for your next big deal? Get going with a Data Suite with Trryst. Share data about your company with your deal consultants, buyers and other partners in complete confidence. What is more, convene video calls or solicit feedback from deal participants, all on the powerful Trryst platform",
  "Get your key Executives in the same room. Whether it is dealing with your Auditors, your CXO team, supplier negotiations or conducting sensitive HR conversations, conduct them with ease and confidence within Trryst Executive Suites",
  "As a special thanks from us for joining us during this launch Stage, we are offering Personal Suites to all our Signups completely free of charge. Think of it as a place to conduct impromptu videocall, say, with your lawyer or CA etc and chat or share documents securely all on the same platform.",
];
const _carouselsExample = [
  "Trryst Boardrooms",
  "Trryst Data Suites",
  "Trryst Executive Suites",
  "Trryst Personal Suite",
].map((item, index) => {
  const imageIndex = index + 1;

  return {
    id: index,
    title: item,
    description: DESCRIPTIONS[index],
    image: mockImgLandingPage(imageIndex),
  };
});
export default function AnimatedSuiteCarousel() {
  const theme = useTheme();
  const carouselRef = useRef(null);
  const [pauseCarousel, setPauseCarousel] = React.useState(false);
  const [currentIndex, setCurrentIndex] = useState(
    theme.direction === "rtl" ? _carouselsExample.length - 1 : 0
  );

  const handlePause = () => {
    if (!pauseCarousel) {
      setPauseCarousel(true);

      carouselRef?.current?.slickPause();
    } else {
      setPauseCarousel(false);

      carouselRef?.current?.slickNext();
      carouselRef?.current?.slickPlay();
    }
  };

  const settings = {
    speed: 2000,
    dots: false,
    fade: true,
    arrows: false,
    autoplay: !pauseCarousel,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === "rtl"),
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <Card sx={{ maxHeight: 700, borderRadius: 0 }}>
      <Slider ref={carouselRef} {...settings}>
        {_carouselsExample.map((item, index) => (
          <CarouselItem
            key={item.id}
            item={item}
            isActive={index === currentIndex}
          />
        ))}
      </Slider>

      <CarouselArrowIndex
        index={currentIndex}
        total={_carouselsExample.length}
        position="right"
        onNext={handleNext}
        onPause={handlePause}
        onPrevious={handlePrevious}
        pauseState={pauseCarousel}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  isActive: PropTypes.bool,
  item: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
  }),
};

function CarouselItem({ item, isActive }) {
  const theme = useTheme();
  const { image, title } = item;

  return (
    <Paper sx={{ position: "relative" }}>
      <LazyImage alt={title} src={image} ratio="16/9" />
      <Box
        sx={{
          top: 0,
          width: "100%",
          height: "100%",

          position: "absolute",
          backgroundImage: `linear-gradient(to left, ${
            theme.palette.grey[900]
          } 0%,${alpha(theme.palette.grey[700], 0.8)} 50%, ${alpha(
            theme.palette.grey[300],
            0.5
          )} 100%)`,
        }}
      />

      <CardContent
        component={MotionContainer}
        animate={isActive}
        action
        sx={{
          top: 0,
          right: 0,
          height: "100%",
          pt: 5,
          maxWidth: 480,
          width: "100%",
          textAlign: "left",
          position: "absolute",
          color: "common.white",
        }}
      >
        <div>
          <Typography variant="h3" color="secondary" gutterBottom>
            {item.title}
          </Typography>

          <Typography variant="subtitle1" sx={{ my: 3 }} gutterBottom>
            {item.description}
          </Typography>
          <NextMuiLink href="#">
            <Button variant="contained" sx={{ mt: 3 }}>
              Know More
            </Button>
          </NextMuiLink>
        </div>
      </CardContent>
    </Paper>
  );
}
