import React from "react";
import { Typography } from "@mui/material";
import { HeadlineUnderlineEffectTypography } from "blocks/atoms/uistyles";
import { isMobile } from "react-device-detect";

export default function Headlineunderlineeffecttext({
  text = "Unfragment Your Conversations",
  sx = {},
}) {
  if (isMobile) {
    return (
      <Typography
        color="primary"
        variant="h3"
        sx={{ mt: 7, textAlign: "center", ...sx }}
      >
        {text}
      </Typography>
    );
  }
  return (
    <HeadlineUnderlineEffectTypography
      color="primary"
      variant="h1"
      sx={{ px: 1, textAlign: { xs: "center", md: "left" }, ...sx }}
    >
      {text}
    </HeadlineUnderlineEffectTypography>
  );
}
