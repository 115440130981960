import React from "react";
import { Box, Container } from "@mui/material";
import { LandingFooter } from "blocks/views/Layouts/LandingPageLayout";
import AboutUsIntroduction from "./AboutUsIntroduction";
// import Features from "./Features";
import AboutUsOrigins from "./AboutUsOrigins";
// import WhatNext from "./WhatNext";
import AboutUsFeatures from "./AboutUsFeatures";
import AboutUsWhatNext from "./AboutUsWhatNext";

export default function BasicAboutIntro() {
  return (
    <Box>
      <Container maxWidth="lg">
        <AboutUsIntroduction />
        <AboutUsFeatures />
        {/* <Features /> */}
        <AboutUsOrigins />
        <AboutUsWhatNext />
        {/* <WhatNext /> */}
      </Container>
      <LandingFooter />
    </Box>
  );
}
