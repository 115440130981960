// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import flashFill from "@iconify/icons-eva/flash-fill";
// next
import NextLink from "next/link";
// material
import { styled, useTheme } from "@mui/material/styles";
import { Box, Link, Stack, Button, Container, Typography } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "routes/paths";
//
import {
  MotionContainer,
  varWrapEnter,
  varFade,
} from "blocks/atoms/uieffects/animate";
import { SignupButton } from "blocks/atoms";

import { useIntl } from "react-intl";

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.grey[400],
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.up("xs")]: {
    width: "100%",
    height: "100vh",
    top: 0,
    left: 0,

    position: "fixed",
  },
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(
  ({ theme }) => ({
    zIndex: 10,
    maxWidth: 650,
    margin: "auto",
    textAlign: "center",
    position: "relative",

    height: "100%",
    flex: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      margin: "unset",
      textAlign: "left",
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  })
);

const HeroOverlayStyle = styled(motion.img)({
  zIndex: 9,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

const HeroImgStyle = styled(motion.img)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 8,
  width: "100%",
  margin: "auto",
  position: "absolute",
  filter: `drop-shadow(40px 80px 80px rgba(0, 0, 0, 0.48))`,
  [theme.breakpoints.up("lg")]: {
    right: "8%",
    width: "auto",
    height: "48vh",
  },
}));

// ----------------------------------------------------------------------

export default function LandingHero() {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const theme = useTheme();
  return (
    <MotionContainer>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        <HeroOverlayStyle
          alt="overlay"
          src="/static/overlay.svg"
          variants={varFade().in}
        />

        <HeroImgStyle
          alt="hero"
          src="/static/home/hero.png"
          variants={varFade().inUp}
        />

        <Container maxWidth="lg">
          <ContentStyle>
            <motion.div variants={varFade().in}>
              <Typography variant="h1" sx={{ color: "common.white" }}>
                {`${t("heroBlock.introducing")}`}
                <span
                  style={{
                    color: theme.palette.secondary.main,
                    marginLeft: theme.spacing(1),
                  }}
                >
                  {" "}
                  {`${t("heroBlock.brandTitle")}`}{" "}
                </span>
              </Typography>
              <Typography
                variant="h1"
                sx={{ color: "secondary.main", ml: 1 }}
              ></Typography>
            </motion.div>

            <motion.div variants={varFade().in}>
              <Typography sx={{ color: "common.white" }} variant="h6">{`${t(
                "heroBlock.mainBlurb"
              )}`}</Typography>
            </motion.div>

            <motion.div variants={varFade().inRight}>
              <SignupButton />
            </motion.div>

            {/*<Stack
              direction="row"
              spacing={1.5}
              justifyContent={{ xs: "center", md: "flex-start" }}
            >
              <motion.img
                variants={varFade().inRight}
                src="/static/home/ic_m_sketch.svg"
              />
              <motion.img
                variants={varFade().inRight}
                src="/static/home/ic_m_figma.svg"
              />
              <motion.img
                variants={varFade().inRight}
                src="/static/home/ic_m_material.svg"
              />
              <motion.img
                variants={varFade().inRight}
                src="/static/home/ic_m_react.svg"
              />
              <motion.img
                variants={varFade().inRight}
                src="/static/home/ic_m_js.svg"
              />
              <motion.img
                variants={varFade().inRight}
                src="/static/home/ic_m_ts.svg"
              />
            </Stack>*/}
          </ContentStyle>
        </Container>
      </RootStyle>
      <Box sx={{ height: { xs: "100vh" } }} />
    </MotionContainer>
  );
}
