import React from "react";
import { Typography, Box, Button, Grid, Container } from "@mui/material";
import Image from "next/image";

export default function AboutUsIntroduction() {
  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Container maxWidth="md" sx={{ textAlign: "center", mt: 4 }}>
        <Typography variant="h1">About Trryst</Typography>
        <Typography variant="h5" color="#727287" sx={{ my: 4 }}>
          We are on a mission to facilitate human conversations with machine
          intelligence
        </Typography>
      </Container>
      <Button
        sx={{ my: 4 }}
        variant="contained"
        color="primary"
        href={"/home/contactus"}
      >
        FREE DEMO
      </Button>
      <Box sx={{ position: "relative", height: 300, width: 300, mt: 3 }}>
        <Image
          alt="Enterprise Conversations"
          src="/static/images/Enterprise_Conversation.png"
          sizes={"50vw"}
          fill
          priority
        />
      </Box>
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Typography variant="h1">
          Making Enterprise Conversations easy
        </Typography>
        <Typography variant="h5" color="#727287" sx={{ my: 2 }}>
          In the modern era, Hybrid and Remote working has come here to stay.
          Enterprises are still struggling with how to contain and streamline
          their communications with their key stakeholders. Whatsapp, Zoom and
          Google Meet threw a lifeline, but like the proverbial whack-a-mole,
          new issues such as security, access controls, fragmentation rear their
          ugly heads. Cases of lost business opportunities, legal tangles,
          corporate data loss etc has become an everyday occurrence.
        </Typography>
        <Typography variant="h5" color="#727287" sx={{ my: 4 }}>
          There is a glaring gap in the marketplace. A recent study of more than
          2000 employees by Corel, indicates that 70% of the employees polled
          attribute poor collaboration is limiting their productivity and
          wasting their time. 41% are considering leaving their job due to such
          negative externalities. It is in this backdrop, that Trryst was
          founded. With the mission to create a platform where conversations can
          be truly unfettered and the power of AI harnessed to ensure enterprise
          conversations can become desirable business outcomes.
        </Typography>
      </Box>
      <Button
        sx={{ my: 4 }}
        variant="contained"
        color="primary"
        href={"/home/contactus"}
      >
        CONTACT US
      </Button>
    </Container>
  );
}
