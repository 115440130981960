// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Card,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
//
import { varFade, MotionInView } from "blocks/atoms/uieffects/animate";
import { useIntl } from "react-intl";
// ----------------------------------------------------------------------

const shadowIcon = (color) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`;

const RootStyle = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grey[400],
  paddingTop: theme.spacing(7),
  [theme.breakpoints.up("md")]: {
    paddingBottom: theme.spacing(7),
  },
}));

const CardStyle = styled(Card)(({ theme }) => {
  const shadowCard = (opacity) =>
    theme.palette.mode === "light"
      ? alpha(theme.palette.grey[500], opacity)
      : alpha(theme.palette.common.black, opacity);

  return {
    maxWidth: 380,
    minHeight: 400,
    margin: "auto",
    textAlign: "center",
    padding: theme.spacing(5, 2, 0),
    boxShadow: `-40px 40px 80px 0 ${shadowCard(0.48)}`,
    transition: "all 1500ms ease",
    [theme.breakpoints.up("md")]: {
      boxShadow: "none",
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    "&.cardLeft": {
      [theme.breakpoints.up("md")]: { marginTop: 0 },
    },
    "&.cardCenter": {
      [theme.breakpoints.up("md")]: { marginTop: 0 },
    },
    "&.cardRight": {
      [theme.breakpoints.up("md")]: { marginTop: 0 },
    },
    "&:hover": {
      [theme.breakpoints.up("md")]: {
        marginTop: -50,
        backgroundColor: theme.palette.background.paper,

        boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
        "&:before": {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          content: "''",
          margin: "auto",
          position: "absolute",
          width: "calc(100% - 5px)",
          height: "calc(100% - 5px)",
          padding: theme.spacing(2),
          borderRadius: theme.shape.borderRadiusMd,
          backgroundColor: theme.palette.background.paper,
          boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
        },
      },
    },
  };
});

const CardIconStyle = styled("img")(({ theme }) => ({
  width: 50,
  height: 50,
  margin: "auto",
  marginBottom: theme.spacing(3),
  filter: shadowIcon(theme.palette.primary.main),
}));

// ----------------------------------------------------------------------

export default function LandingMinimalHelps() {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  const CARDS = [
    {
      icon: "/static/icons/managementicon.png",
      title: `${t(`features.featurelist1.subtitle`)}`,
      description: `${t(`features.featurelist1.text`)}`,
    },
    {
      icon: "/static/icons/securityshield.png",
      title: `${t(`features.featurelist2.subtitle`)}`,
      description: `${t(`features.featurelist2.text`)}`,
    },
    {
      icon: "/static/icons/videocall.png",
      title: `${t(`features.featurelist3.subtitle`)}`,
      description: `${t(`features.featurelist3.text`)}`,
    },
    {
      icon: "/static/icons/dashboard.png",
      title: `${t(`features.featurelist4.subtitle`)}`,
      description: `${t(`features.featurelist4.text`)}`,
    },
    {
      icon: "/static/icons/videoconference.png",
      title: `${t(`features.featurelist5.subtitle`)}`,
      description: `${t(`features.featurelist5.text`)}`,
    },
    {
      icon: "/static/icons/ai.png",
      title: `${t(`features.featurelist6.subtitle`)}`,
      description: `${t(`features.featurelist6.text`)}`,
    },
  ];

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Box sx={{ mb: { xs: 5, md: 10 } }}>
          <MotionInView variants={varFade().inUp}>
            <Typography
              component="p"
              variant="overline"
              sx={{ mb: 2, color: "text.primary", textAlign: "center" }}
            >
              {`${t("features.overline")}`}
            </Typography>
          </MotionInView>
          <MotionInView variants={varFade().inDown}>
            <Typography variant="h3" sx={{ textAlign: "center" }}>
              {`${t("features.headline")}`}
            </Typography>
          </MotionInView>
        </Box>

        <Grid container spacing={isDesktop ? 10 : 5}>
          {CARDS.map((card, index) => (
            <Grid key={card.title} item xs={12} md={4}>
              <MotionInView variants={varFade().inUp}>
                <CardStyle
                  className={
                    (index % 3 === 0 && "cardLeft") ||
                    (index % 3 === 1 && "cardCenter") ||
                    (index % 3 === 2 && "cardRight")
                  }
                >
                  <CardIconStyle
                    src={card.icon}
                    alt={card.title}
                    sx={{
                      ...(index === 0 && {
                        filter: (theme) => shadowIcon(theme.palette.info.main),
                      }),
                      ...(index === 1 && {
                        filter: (theme) => shadowIcon(theme.palette.error.main),
                      }),
                    }}
                  />
                  <Typography variant="h5" paragraph>
                    {card.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: isLight ? "text.secondary" : "common.white" }}
                  >
                    {card.description}
                  </Typography>
                </CardStyle>
              </MotionInView>
            </Grid>
          ))}
        </Grid>
      </Container>
    </RootStyle>
  );
}
