// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import {
  Box,
  Grid,
  Card,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { alpha, useTheme, styled } from "@mui/material/styles";
import Image from "next/image";

import { varFade, MotionInView } from "blocks/atoms/uieffects/animate";
import { useIntl } from "react-intl";
import AnimatedSuiteCarousel from "./AnimatedSuiteCarousel";

const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(7),
  [theme.breakpoints.up("md")]: {
    paddingBottom: theme.spacing(7),
  },
}));

export default function LandingSuitesCarousel() {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  return (
    <RootStyle>
      <Container maxWidth={false} disableGutters>
        <Box sx={{ mb: { xs: 2, md: 3 } }}>
          <AnimatedSuiteCarousel />
        </Box>
      </Container>
    </RootStyle>
  );
}
