// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
// material
import { styled } from "@mui/material/styles";
import { Button, Box, Container, Typography } from "@mui/material";
//
import { varFade, MotionInView } from "blocks/atoms/uieffects/animate";
import { SignupButton } from "blocks/atoms";

import Rocketman from "blocks/atoms/features/Rocketman";
// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 456,
  margin: "auto",
  overflow: "hidden",
  paddingBottom: theme.spacing(10),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundImage: `linear-gradient(135deg,
    ${theme.palette.primary.main} 0%,
    ${theme.palette.primary.dark} 100%)`,
  [theme.breakpoints.up("md")]: {
    display: "flex",
    maxWidth: "100%",
    paddingBottom: 0,
    alignItems: "center",
  },
}));

// ----------------------------------------------------------------------

export default function LandingAdvertisement() {
  return (
    <Container maxWidth="lg" sx={{ my: 3 }}>
      <ContentStyle>
        <MotionInView
          variants={varFade().inUp}
          sx={{
            mb: { xs: 3, md: 0 },
          }}
        >
          {/*<Box component="img" alt="rocket" src="/static/home/rocket.png" sx={{ maxWidth: 460, width: 1 }} />*/}
          <Box sx={{ maxWidth: 460, backgroundColor: "transparent" }}>
            <Rocketman />
          </Box>
        </MotionInView>

        <Box
          sx={{
            textAlign: { xs: "center", md: "center" },
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MotionInView
            variants={varFade().inDown}
            sx={{ color: "common.white", mb: 5 }}
          >
            <Typography variant="h2">
              Get started with
              <Typography
                component="span"
                variant="h2"
                sx={{ color: "secondary.main", mx: 2 }}
              >
                Trryst
              </Typography>
            </Typography>
          </MotionInView>
          <MotionInView variants={varFade().inDown}>
            <SignupButton
              sx={{
                whiteSpace: "nowrap",
                boxShadow: (theme) => theme.customShadows.z8,
                color: (theme) =>
                  theme.palette.getContrastText(theme.palette.common.white),
                bgcolor: "common.white",
                "&:hover": { bgcolor: "grey.300" },
                alignSelf: "center",
              }}
            />
            {/*<Button
              size="large"
              variant="contained"
              target="_blank"
              href="https://material-ui.com/store/items/minimal-dashboard/"
              sx={{
                whiteSpace: "nowrap",
                boxShadow: (theme) => theme.customShadows.z8,
                color: (theme) =>
                  theme.palette.getContrastText(theme.palette.common.white),
                bgcolor: "common.white",
                "&:hover": { bgcolor: "grey.300" },
              }}
            >
              Purchase Now
            </Button>*/}
          </MotionInView>
        </Box>
      </ContentStyle>
    </Container>
  );
}
