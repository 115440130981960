// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
// material
import { styled } from "@mui/material/styles";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import {
  BoardroomIcon,
  PersonalSuiteIcon,
  CollaborateIcon,
} from "blocks/atoms/customicons";
import { MotionInView, varFade } from "blocks/atoms/uieffects/animate";
import PricingPlanCard from "./PricingPlanCard";

// ----------------------------------------------------------------------

const PLANS = [
  {
    subscription: "Free",
    icon: "/static/customicons/freesuiteicon.svg",
    price: 0,
    credits: 0,
    caption: "Great for personal use",
    recommended: false,
    lists: [
      { text: "Limited to One Suite", isAvailable: true },
      { text: "50 included Credits every month", isAvailable: true },
      {
        text: "Limited Application features",
        isAvailable: true,
      },
      {
        text: "No Organization/Suite teams",
        isAvailable: true,
      },

      { text: "Suited for individuals", isAvailable: true },
    ],
    labelAction: "Get Started",
  },
  {
    subscription: "basic",
    icon: "/static/customicons/basicsuiteicon.svg",
    price: 49,
    credits: "500 Credits",
    caption: "For regular use",
    recommended: true,
    lists: [
      { text: "All Platform features", isAvailable: true },
      { text: "Max. 25 members per suite", isAvailable: true },
      {
        text: "Basic AI features and Analytics",
        isAvailable: true,
      },
      {
        text: "Best suited for small teams",
        isAvailable: true,
      },
    ],
    specialSuiteList: [
      {
        text: "Board Suite",
        infoElementText:
          "Suite specifically designed for Boardroom conversations with customized workflows suited to handle Board requirements such as Board Meeting Scheduling, Resolutions etc",
        addonPrice: "39",
        isActive: true,
      },
      {
        text: "Data Suite",
        infoElementText:
          "Built for Datarooms, Due Diligence, RFP and other Data-sensitive situations. Suite workflows and analytics customized towards Document Management, Data Security and Dataroom Usage Analytics",
        addonPrice: "19",
        isActive: true,
      },
      {
        text: "Sales Suite",
        infoElementText:
          "Coming Soon. Suite specifically designed for outreach purposes such as Sales, Marketing, Customer Support, Analyst Outreach etc",
        addonPrice: "9",
        isActive: false,
      },
    ],
    labelAction: "Get Started",
  },
  {
    subscription: "pro",
    icon: "/static/customicons/prosuiteicon.svg",
    price: 99,
    credits: "2500 Credits",
    caption: "For Hybrid offices",
    recommended: false,
    lists: [
      { text: "All Application features", isAvailable: true },
      { text: "Suite Customizations", isAvailable: true },
      {
        text: "All Analytics & AI features",
        isAvailable: true,
      },
      { text: "Best Suited for large Enterprises", isAvailable: false },
    ],
    specialSuiteList: [
      {
        text: "Board Suite",
        infoElementText:
          "Suite specifically designed for Boardroom conversations with customized workflows suited to handle Board requirements such as Board Meeting Scheduling, Resolutions etc",
        addonPrice: "89",
        isActive: true,
      },
      {
        text: "Data Suite",
        infoElementText:
          "Built for Datarooms, Due Diligence, RFP and other Data-sensitive situations. Suite workflows and analytics customized towards Document Management, Data Security and Dataroom Usage Analytics",
        addonPrice: "59",
        isActive: true,
      },
      {
        text: "Sales Suite",
        infoElementText:
          "Coming Soon. Suite specifically designed for outreach purposes such as Sales, Marketing, Customer Support, Analyst Outreach etc",
        addonPrice: "29",
        isActive: false,
      },
    ],
    labelAction: "Get Started",
  },
];

const RootStyle = styled(Box)(({ theme }) => ({
  margin: "auto",
  display: "flex",
  position: "relative",
  alignItems: "center",
  width: "100%",
  border: "1px solid blue",
  flexDirection: "column",
  padding: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(3),
  },
}));

export default function ConsolidatedPricing() {
  const isMobile = !useMediaQuery("(min-width:1000px)");
  function animateAction(index) {
    if (index % 3 === 0) {
      return varFade().inLeft;
    } else if (index % 3 === 2) {
      return varFade().inRight;
    } else return varFade().in;
  }

  return (
    <FlowBox>
      {/*<Box sx={{ display: 'block', px: 3, ...(!isMobile ? { maxWidth: 350 } : {}) }}>
            <Typography variant="h6" sx={{ textAlign: 'center', my: 2 }}>
              Setup and Conduct AI-enabled 'Smart' meetings with ease.
            </Typography>
        </Box>*/}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 5,
          justifyContent: "space-evenly",
          alignItems: "stretch",
          width: "100%",

          p: 3,
        }}
      >
        {PLANS.map((card, index) => (
          <Box
            key={card.subscription}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <MotionInView
              variants={animateAction(index)}
              sx={{
                display: "flex",
                flexDirection: "column",

                flexGrow: 1,
              }}
            >
              <PricingPlanCard card={card} index={index} />
            </MotionInView>
          </Box>
        ))}
      </Box>
    </FlowBox>
  );
}
