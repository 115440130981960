// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import NextMuiLink from "blocks/atoms/NextMuiLink";
import checkmarkFill from "@iconify/icons-eva/checkmark-fill";
// material
import { styled } from "@mui/material/styles";
import {
  Card,
  Button,
  Typography,
  Box,
  Stack,
  Link,
  Grid,
  Switch,
  useMediaQuery,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "routes/paths";
//
import Label from "blocks/atoms/uicomponents/Label";
import {
  PlanFreeIcon,
  PlanStarterIcon,
  PlanPremiumIcon,
} from "blocks/atoms/graphics";
import { MotionInView, varFade } from "blocks/atoms/uieffects/animate";
import PricingPlanCard from "blocks/views/LandingPage/foundations/pricing/PricingPlanCard";
import { PersonalSuiteIcon } from "blocks/atoms/customicons";
// ----------------------------------------------------------------------

const PLANS = [
  {
    subscription: "Personal",
    icon: <PersonalSuiteIcon color="primary" />,
    price: 0,
    credits: "250 Credits",
    caption: "Perfect for personal use",
    recommended: true,
    lists: [
      { text: "Only 1 team member (You)", isAvailable: true },
      { text: "Included 250 Credits every month", isAvailable: true },
      { text: "Group Video calls", isAvailable: true },

      { text: "Basic Dataroom with Dropbox-like features", isAvailable: true },
      { text: "Calendaring & Meeting Scheduling", isAvailable: true },
      { text: "Transcription & Recordings Search", isAvailable: true },
      { text: "AI-based meeting summarization", isAvailable: true },
    ],
    labelAction: "Start Now",
  },
];

const RootStyle = styled(Box)(({ theme }) => ({
  margin: "auto",
  display: "flex",
  position: "relative",
  alignItems: "center",
  width: "100%",
  flexDirection: "column",
  padding: theme.spacing(2),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(3),
  },
}));

export default function PersonalSuitePricing() {
  function animateAction(index) {
    if (index % 3 === 0) {
      return varFade().inLeft;
    } else if (index % 3 === 2) {
      return varFade().inRight;
    } else return varFade().in;
  }

  const isMobile = !useMediaQuery("(min-width:1000px)");

  return (
    <RootStyle elevation={0}>
      {/*<Box sx={{ my: 5 }}>
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Typography variant="overline" sx={{ mr: 1.5 }}>
            MONTHLY
          </Typography>
          <Switch />
          <Typography variant="overline" sx={{ ml: 1.5 }}>
            YEARLY (save 10%)
          </Typography>
        </Stack>
        <Typography variant="caption" align="right" sx={{ color: 'text.secondary', display: 'block' }}>
          * Plus applicable taxes
  </Typography>
      </Box>*/}
      <Box
        sx={{
          my: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Free Personal Suite - 'MySuite'
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center", my: 2 }}>
          The personal Suite is a great way to get acquainted with the
          capabilities of the Trryst platform.
        </Typography>
        <Box
          sx={{
            my: 1,
            display: "flex",
            flexDirection: !isMobile ? "row-reverse" : "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "block",
              px: 3,
              ...(!isMobile ? { maxWidth: 550 } : {}),
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center", my: 2 }}>
              Setup and Conduct AI-enabled 'Smart' meetings with ease.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {PLANS.map((card, index) => (
              <Box key={card.subscription}>
                <MotionInView variants={animateAction(index)}>
                  <PricingPlanCard card={card} index={index} />
                </MotionInView>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </RootStyle>
  );
}
