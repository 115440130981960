// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import NextMuiLink from "blocks/atoms/NextMuiLink";
import checkmarkFill from "@iconify/icons-eva/checkmark-fill";
// material
import { styled } from "@mui/material/styles";
import {
  Card,
  Button,
  Typography,
  Box,
  Stack,
  Link,
  Grid,
  Switch,
  useMediaQuery,
  Badge,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "routes/paths";
//
import Label from "blocks/atoms/uicomponents/Label";
import {
  PlanFreeIcon,
  PlanStarterIcon,
  PlanPremiumIcon,
} from "blocks/atoms/graphics";
import { MotionInView, varFade } from "blocks/atoms/uieffects/animate";
import PricingPlanCard from "blocks/views/LandingPage/foundations/pricing/PricingPlanCard";
import { ExecSuiteIcon } from "blocks/atoms/customicons";

// ----------------------------------------------------------------------

const PLANS = [
  {
    subscription: "basic",
    icon: <ExecSuiteIcon color="primary" />,
    price: 89,
    credits: "2500 Credits",
    caption: "For Occasional High Impact Stakeholder Meetings",
    recommended: true,
    lists: [
      { text: "Superior Quality HD VideoCalls", isAvailable: true },
      { text: "1 TB Secure Datavault", isAvailable: true },
      {
        text: "Unlimited Resolutions, Tasks, Meetings, Surveys",
        isAvailable: true,
      },
      { text: "Smart & Searchable Recordings", isAvailable: true },
      { text: "AI-enabled Transcription & Search", isAvailable: true },
      { text: "Magic Meeting Minutes with AI", isAvailable: true },
      { text: "Best Suited for Small Boards", isAvailable: true },
    ],
    labelAction: "Choose Basic",
  },
  {
    subscription: "pro",
    icon: (
      <Badge
        badgeContent="PRO"
        color="primary"
        sx={{ "& .MuiBadge-badge": { borderRadius: 0.5 } }}
      >
        <ExecSuiteIcon color="primary" />
      </Badge>
    ),
    price: 199,
    credits: "Unlimited Use",
    caption: "For Regular Use",
    recommended: false,
    lists: [
      { text: "Unlimited VideoCalls", isAvailable: true },
      { text: "10 TB Secure Datavault", isAvailable: true },
      {
        text: "Unlimited Resolutions, Tasks, Meetings, Surveys",
        isAvailable: true,
      },
      { text: "Unlimited Recordings", isAvailable: true },
      { text: "Unlimited Transcription & Search", isAvailable: true },
      { text: "Magic Meeting Minutes with AI", isAvailable: true },
      { text: "* Subject to Fair use policy", isAvailable: false },
    ],
    labelAction: "Choose PRO",
  },
];

const RootStyle = styled(Box)(({ theme }) => ({
  margin: "auto",
  display: "flex",
  position: "relative",
  alignItems: "center",
  width: "100%",
  flexDirection: "column",
  padding: theme.spacing(2),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(3),
  },
}));

export default function ExecutiveSuitePricing() {
  const isMobile = !useMediaQuery("(min-width:1000px)");
  function animateAction(index) {
    if (index % 3 === 0) {
      return varFade().inLeft;
    } else if (index % 3 === 2) {
      return varFade().inRight;
    } else return varFade().in;
  }

  return (
    <RootStyle elevation={0}>
      <Box
        sx={{
          my: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          width: "100%",
          height: "100%",
        }}
      >
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Boundaryless Team Collaboration
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center", my: 2 }}>
          The Integrated Information + Communications + Meeting Management
          Solution
        </Typography>
        <Box
          sx={{
            my: 1,
            display: "flex",
            flexDirection: !isMobile ? "row-reverse" : "column",
            alignItems: "center",
          }}
        >
          {/*<Box sx={{ display: 'block', px: 3, ...(!isMobile ? { maxWidth: 350 } : {}) }}>
            <Typography variant="h6" sx={{ textAlign: 'center', my: 2 }}>
              Setup and Conduct AI-enabled 'Smart' meetings with ease.
            </Typography>
        </Box>*/}
          <Box
            sx={{
              display: "flex",
              flexGrow: 3,
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {PLANS.map((card, index) => (
              <Box key={card.subscription} sx={{ m: 1 }}>
                <MotionInView variants={animateAction(index)}>
                  <PricingPlanCard card={card} index={index} />
                </MotionInView>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </RootStyle>
  );
}
