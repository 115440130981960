import React, { useRef } from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  useTheme,
} from "@mui/material";
import Image from "next/image";
import Xarrow from "react-xarrows";
import { useIsMobileBreakpoint } from "hooks";

function AboutUsWhatNext() {
  const theme = useTheme();
  const isMobile = useIsMobileBreakpoint();
  return (
    <Container maxWidth="md" sx={{ pb: 10 }}>
      <Xarrow
        start="concept1"
        end="concept2"
        startAnchor={"bottom"}
        endAnchor={isMobile ? "top" : "right"}
        path="grid"
        animateDrawing={3}
        tailShape="circle"
        showTail
        headSize={4}
        tailSize={4}
        curveness={0.99}
        dashness={true}
        color={theme.palette.primary.light}
      />
      <Xarrow
        start="concept3"
        end="concept4"
        startAnchor={"bottom"}
        endAnchor={isMobile ? "top" : "left"}
        path="grid"
        animateDrawing={3}
        tailShape="circle"
        showTail
        headSize={4}
        tailSize={4}
        curveness={0.99}
        dashness={true}
        color={theme.palette.primary.light}
      />
      <Xarrow
        start="concept5"
        end="concept6"
        startAnchor={"bottom"}
        endAnchor={isMobile ? "top" : "right"}
        path="grid"
        animateDrawing={3}
        tailShape="circle"
        showTail
        headSize={4}
        tailSize={4}
        curveness={0.99}
        dashness={true}
        color={theme.palette.primary.light}
      />
      <Grid container spacing={3} direction="column">
        <Grid container spacing={4} direction={{ xs: "column", md: "row" }}>
          <Grid item xs={12} sm={6} md={7}>
            <Typography variant="h1">What’s Next?</Typography>
            <Typography variant="h5" color="#727287" sx={{ mt: 4 }}>
              As hybrid organizations evolve, our vision is to ensure Trryst can
              scale up to becoming our customers’ trusted platform for
              privileged conversations.
            </Typography>
            <Typography variant="h5" color="#727287" sx={{ mt: 2 }}>
              We believe we are still very much at the adolescence of a
              revolution in AI technologies and its ability to shape modern
              enterprise business processes. Trryst will continue to play an
              active role in shaping this by effectively leveraging a range of
              AI Technologies to ensure these become a staple in modern day
              enterprises.
            </Typography>
            <Typography variant="h5" color="#727287" sx={{ mt: 2 }}>
              This is just the beginning; and we see immense possibilities to
              replicate human-like, boundaryless interactions using the best of
              technology available. We would love you to come on board with us
              on this journey and help shape Trryst!
            </Typography>

            <Button
              sx={{ my: 4 }}
              variant="contained"
              color="primary"
              href={"/home/contactus"}
            >
              REQUEST DEMO
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                minHeight: 300,
              }}
            >
              <Image
                alt="What Next"
                src="/static/images/What_next.png"
                fill
                sizes={"50vw"}
                style={{
                  objectFit: "contain",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="#727287"
                sx={{ mt: 1, textAlign: "center" }}
                id={"concept1"}
              >
                General release of Trryst multimodal hybrid conversation
                platform
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          spacing={1}
          direction="column"
          sx={{ mt: 6 }}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item xs={12} sm={6} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                minHeight: 300,
                maxHeight: 500,
                minWidth: 300,
                maxWidth: 500,
              }}
              id={"concept2"}
            >
              <Image
                alt="Enhanced Experience"
                src="/static/images/EnhanceExperience.png"
                fill
                sizes={"50vw"}
                style={{
                  objectFit: "contain",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              variant="h5"
              color="#727287"
              sx={{ my: 2, textAlign: "center" }}
              id={"concept3"}
            >
              Enhance customer experience with continued upgrade of AI and smart
              analytics
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          spacing={2}
          direction="column"
          sx={{ mt: isMobile ? 3 : -6 }}
          justifyContent="flex-end"
        >
          <Grid item xs={12} sm={6} md={5} id={"concept4"} alignSelf="flex-end">
            <Image
              alt="Smart Analytics"
              src="/static/images/Analytics.png"
              height={300}
              width={300}
              style={{
                maxWidth: "100%",

                objectFit: "contain",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} alignSelf="flex-end">
            <Typography
              variant="h5"
              color="#727287"
              sx={{
                my: 2,
                textAlign: "center",
                flexWrap: "wrap",
                maxWidth: 400,
              }}
              id={"concept5"}
            >
              Not all conversations are alike - deepen and broaden the scope of
              vertical conversation solutions such as datarooms, boardrooms,
              sales rooms etc
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          spacing={1}
          direction="column"
          sx={{ mt: isMobile ? 3 : -6 }}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item xs={12} sm={6} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                minHeight: 300,
                maxHeight: 500,
                minWidth: 300,
                maxWidth: 500,
              }}
              id={"concept6"}
            >
              <Image
                alt="Enabling Business Outcomes"
                src="/static/images/BusinessOutcome.png"
                fill
                sizes={"50vw"}
                style={{
                  objectFit: "contain",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              variant="h5"
              color="#727287"
              sx={{ my: 2, textAlign: "center", maxWidth: 400 }}
            >
              Deepen integration of evolving technologies around machine-human
              interfaces and AI models to richen and enliven human conversations
              for better business outcomes
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
export default AboutUsWhatNext;
