import React from "react";
import Typist from "react-typist-component";

export default function TypingEffectText({
  text = "Videocall",
  delayInterval = 7000,
  typingDelay = 500,
  onTypingDone = () => null,
  loop = true,
}) {
  return (
    <Typist
      typingDelay={typingDelay}
      loop={loop}
      onTypingDone={onTypingDone}
      restartKey={text}
    >
      {text}
      <Typist.Delay ms={delayInterval} />
      <Typist.Backspace count={text.length} />
    </Typist>
  );
}
