import React, { useRef } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Image from "next/image";
import Xarrow from "react-xarrows";

function AboutUsFeatures() {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <Connector
          el1={ref1?.current}
          el2={ref2?.current}
          shape="narrow-s"
          direction="t2b"
          roundCorner={true}
          endArrow={true}
        />

        <Connector
          el1={ref2?.current}
          el2={ref3?.current}
          shape="narrow-s"
          direction="b2t"
          roundCorner={true}
          endArrow={true}
        /> */}
      <Xarrow
        start="box1"
        end="box2"
        startAnchor={"bottom"}
        endAnchor="top"
        path="grid"
        animateDrawing={2}
        tailShape="circle"
        showTail
        headSize={4}
        tailSize={4}
        curveness={0.99}
        dashness={true}
        color={theme.palette.secondary.main}
      />
      <Xarrow
        start="box2"
        end="box3"
        startAnchor={"bottom"}
        endAnchor="top"
        path="grid"
        animateDrawing={4}
        tailShape="circle"
        showTail
        headSize={4}
        tailSize={4}
        curveness={0.99}
        dashness={true}
        color={theme.palette.secondary.main}
      />
      <Grid container direction="column">
        <Grid item container xs={12} alignItems="center" sx={{ py: 12 }}>
          <Grid item xs={3} md={4}>
            <Box
              id="box1"
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                height: "100%",
                minHeight: isMobile ? 100 : 200,
                position: "relative",
                justifyContent: "center",
              }}
            >
              <Image
                alt="Boundaryless Communications with Trryst"
                src="/static/images/BoundaryLessComm.png"
                fill
                style={{ objectFit: "contain" }}
              />
            </Box>
          </Grid>
          <Grid item xs={9} md={8}>
            <Box sx={{ textAlign: "center", ml: 10 }}>
              <Typography variant="h1">Boundaryless Communications</Typography>
              <Typography variant="h5" color="#727287">
                Make cross organization communications simple
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center" sx={{ py: 12 }}>
          <Grid item xs={9} md={8}>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h1">Bringing Alive Conversations</Typography>
              <Typography variant="h5" color="#727287">
                With a platform that allows frictionless multimodal
                communications (Files + Videocall + Chats + Work Management)
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3} md={4}>
            <Box
              id="box2"
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                height: "100%",
                minHeight: isMobile ? 100 : 200,
                position: "relative",
                justifyContent: "center",
              }}
            >
              <Image
                alt="Better conversations"
                src="/static/images/Conversation.png"
                fill
                style={{ objectFit: "contain" }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center" sx={{ py: 12 }}>
          <Grid item xs={3} md={4}>
            <Box
              id="box3"
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                height: "100%",
                minHeight: isMobile ? 100 : 200,
                position: "relative",
                justifyContent: "center",
              }}
            >
              <Image
                alt="Hybrid working made easy with Trryst"
                src="/static/images/Hybrid.png"
                fill
                style={{ objectFit: "contain" }}
              />
            </Box>
          </Grid>
          <Grid item xs={9} md={8}>
            <Box sx={{ textAlign: "center", ml: 20 }}>
              <Typography variant="h1">Hybrid conversation platform</Typography>
              <Typography variant="h5" color="#727287">
                Human conversations work best when augmented by AI
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
export default AboutUsFeatures;
