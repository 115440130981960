// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
// material
import { styled } from "@mui/material/styles";
import { Box, Grid, Container, Typography, IconButton } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
//
import { MotionInView, varFade } from "blocks/atoms/uieffects/animate";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(28, 0),
  backgroundColor: theme.palette.grey[900],
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up("md")]: {
    height: "100%",
    marginBottom: 0,
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
}));

// ----------------------------------------------------------------------

export default function LandingFeaturePreviews() {
  const [clipView, setClipView] = React.useState("videocall");
  const handleViewClick = (str) => {
    setClipView(str);
  };
  return (
    <RootStyle>
      <Container maxWidth="lg" sx={{ position: "relative" }}>
        <Box
          component="img"
          alt="image shape"
          src="/static/home/shape.svg"
          sx={{
            top: 0,
            right: 0,
            bottom: 0,
            my: "auto",
            position: "absolute",
            filter: "grayscale(1) opacity(48%)",
            display: { xs: "none", md: "block" },
          }}
        />

        <Grid
          container
          spacing={5}
          direction="row-reverse"
          justifyContent="space-between"
        >
          <Grid item xs={12} md={7} sx={{ position: "relative" }}>
            <MotionInView threshold={0.5} variants={varFade().inUp}>
              <img alt="light mode" src="/static/home/lightmode.png" />
            </MotionInView>
            <MotionInView
              threshold={0.5}
              variants={varFade().inDown}
              sx={{ top: 0, left: 0, position: "absolute" }}
            >
              <img alt="dark mode" src="/static/home/darkmode.png" />
            </MotionInView>
          </Grid>

          <Grid item xs={12} md={4}>
            <ContentStyle>
              <MotionInView variants={varFade().inUp}>
                <Typography
                  component="p"
                  variant="overline"
                  sx={{ mb: 2, color: "text.disabled", display: "block" }}
                >
                  Meet. Collaborate. Share
                </Typography>
              </MotionInView>

              <MotionInView variants={varFade().inUp}>
                <Typography variant="h3" sx={{ mb: 3, color: "common.white" }}>
                  Unify your Conversations
                </Typography>
              </MotionInView>

              <MotionInView
                variants={varFade().in}
                sx={{
                  borderBottom: "2px solid white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "common.white", my: 2, mr: 2 }}>
                  Integrated Videocalls
                </Typography>
                {/*<IconButton onClick={() => handleViewClick('videocall')}>
                  <ChevronRight sx={{ color: 'common.white' }} />
        </IconButton>*/}
              </MotionInView>
              <MotionInView
                variants={varFade().in}
                sx={{
                  borderBottom: "2px solid white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "common.white", my: 2 }}>
                  Meeting Transcriptions & Recording
                </Typography>
                {/*<IconButton onClick={() => handleViewClick('recording')}>
                  <ChevronRight sx={{ color: 'common.white' }} />
        </IconButton>*/}
              </MotionInView>
              <MotionInView
                variants={varFade().in}
                sx={{
                  borderBottom: "2px solid white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "common.white", my: 2 }}>
                  Secure Datarooms
                </Typography>
                {/*<IconButton onClick={() => handleViewClick('dataroom')}>
                  <ChevronRight sx={{ color: 'common.white' }} />
        </IconButton>*/}
              </MotionInView>
              <MotionInView
                variants={varFade().in}
                sx={{
                  borderBottom: "2px solid white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "common.white", my: 2 }}>
                  Action Tracking & Task scheduling
                </Typography>
                {/*<IconButton onClick={() => handleViewClick('tasks')}>
                  <ChevronRight sx={{ color: 'common.white' }} />
        </IconButton>*/}
              </MotionInView>
            </ContentStyle>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
