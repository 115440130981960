import React, { Fragment } from "react";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  Tooltip,
  ListSubheader,
} from "@mui/material";
import { Info } from "@mui/icons-material";

export default function RenderSuitePriceList({
  title,
  listItems,
  ListProps,
  ListSubheaderTypographyProps,
  ListItemProps,
  ...rest
}) {
  return (
    <List disableGutters {...ListProps} {...rest}>
      <ListSubheader disableGutters sx={{ fontSize: 16, mb: 0 }}>
        {title}
      </ListSubheader>
      {listItems.map((item, index) => (
        <Fragment key={index}>
          <ListItem
            key={index}
            disableGutters
            sx={{ display: "flex" }}
            {...ListItemProps}
            disabled={!item.isActive}
          >
            <Typography
              variant="h6"
              sx={{
                color: "text.primary",
                fontWeight: "bold",
                mr: 1,
                width: "35%",
              }}
            >
              {item.text}
            </Typography>
            {item.infoElementText && (
              <Tooltip title={item.infoElementText} placement="top">
                <Info sx={{ color: "info.lighter", ml: 1 }} />
              </Tooltip>
            )}

            <Typography
              variant="h6"
              sx={{
                color: "text.primary",
                fontWeight: 600,
                flexGrow: 1,
                textAlign: "right",
              }}
            >
              + {item.addonPrice} $
            </Typography>
          </ListItem>
          <Divider />
        </Fragment>
      ))}
    </List>
  );
}
