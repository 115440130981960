import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import Image from "next/image";
import { Circle } from "@mui/icons-material";

export default function AboutUsOrigins() {
  return (
    <Container maxWidth="md" sx={{ mt: 10, mb: 3 }}>
      <Typography
        variant="h1"
        sx={{ mt: 10, textAlign: "center", px: 2, mb: 4 }}
        color="primary"
      >
        What is the platform about?
      </Typography>
      <Grid
        container
        direction={{ xs: "column", md: "row" }}
        alignItems="stretch"
      >
        <Grid item xs={12} md={6} sx={{ pr: 2 }}>
          <Typography variant="h5" color="#727287">
            It is not that there are no solutions available to corporates. There
            is plenty. Dropbox, Teams, Whatsapp, Zoom, Slack, Asana etc just to
            name a few that corporates could use to organize their
            communications. However, each of these suffers from the constraint
            of being a ‘best in class’ product. It does its small defined job
            well while leaving the enterprise high and dry for the rest. This
            has resulted in corporates cobbling together solutions that let
            conversations fall sadly between the cracks. It is a case of a
            marooned sailor with water everywhere but not a drop to drink.
          </Typography>
          <Typography variant="h5" color="#727287" sx={{ mt: 3 }}>
            We founded Trryst with three guiding principles
          </Typography>
          <List sx={{ mt: 2 }}>
            <ListItem>
              <ListItemIcon>
                <Circle fontSize="small" />
              </ListItemIcon>
              <ListItemText
                secondary={
                  <Typography variant="h5" color="#727287">
                    Make cross organization communications simpler with
                    conversations unfettered by organizational boundaries
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Circle fontSize="small" />
              </ListItemIcon>
              <ListItemText
                secondary={
                  <Typography variant="h5" color="#727287">
                    Bringing alive conversations with a platform that allows
                    frictionless multimodal communications (files, video+voice,
                    chats, work management in the same platform and no messy
                    integrations)
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Circle fontSize="small" />
              </ListItemIcon>
              <ListItemText
                secondary={
                  <Typography variant="h5" color="#727287">
                    Human conversations work best when augmented by AI (rather
                    than the other way round)
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: 250,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              heigt: "100%",
              flexGrow: 1,
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",

              p: 2,
              position: "relative",
            }}
          >
            <Image
              alt="About Us"
              src="/static/images/AboutUsIdeaBulb.png"
              fill
              style={{
                objectFit: "contain",
              }}
            />
          </Box>
          <Typography variant="h3" sx={{ alignSelf: "center", ml: 5 }}>
            Feedback Loop
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Typography variant="h5" color="#727287" sx={{ mt: 2 }}>
          ...and so Trryst was born. As an initial product, we started with a
          basic multimodal conversation with a beta suite focused on Boardroom
          conversations. As we started getting getting some valuable customer
          feedback and focussed usage analytics, it only reinforced our belief
          in the platform objectives and the conviction in the problem we were
          trying to solve.
        </Typography>
        <Typography variant="h5" color="#727287" sx={{ mt: 2 }}>
          Soon, we followed up and augmented our flagship conversations platform
          with a whole host of exciting new features - HD quality
          videoconferencing, AI technology to automatically generate meeting
          transcripts and summaries, task management, instant surveys & polls,
          whiteboarding, and ability to video search (we have all have been
          situation when we recall the keyword from meeting, but yearn the
          ability to video search that hours long call!).
        </Typography>
        <Typography variant="h5" color="#727287" sx={{ mt: 2 }}>
          Since its beta release in 2021, we have been trusted by dozens of
          customers that have helped us shape the product what it is today!
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            minHeight: 400,
            flexGrow: 1,
            minWidth: 400,
            mb: 10,
          }}
        >
          <Image
            alt="Feedback"
            src="/static/images/FeedBack.png"
            fill
            sizes={"50vw"}
            style={{
              objectFit: "contain",
            }}
          />
        </Box>
      </Box>
    </Container>
  );
}
