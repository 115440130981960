import React from "react";
import {
  Box,
  Avatar,
  Typography,
  Card,
  CardHeader,
  useMediaQuery,
} from "@mui/material";

export default function HeroSnippet({
  title = "",
  subtitle = "",
  imageSrc = null,
  sx = {},
  ...rest
}) {
  const [isHovered, setIsHovered] = React.useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Card
      sx={{
        p: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: isMobile ? 10 : 5,

        transition: "transform 1s",
        transform: `scale(${isHovered ? 1.03 : 1})`,
        ...sx,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}
    >
      <CardHeader
        sx={{
          p: isMobile ? 1 : 2,
        }}
        disableTypography
        title={
          <Typography variant={isMobile ? "subtitle2" : "h4"}>
            {title}
          </Typography>
        }
        {...(imageSrc && !isMobile
          ? {
              avatar: (
                <Avatar
                  src={imageSrc}
                  sx={{ bgcolor: isHovered ? "secondary" : "primary" }}
                />
              ),
            }
          : {})}
        subheader={
          <Typography
            variant="body1"
            sx={{ mt: 2, display: { xs: "none", md: "block" } }}
          >
            {subtitle}
          </Typography>
        }
      />
    </Card>
  );
}
