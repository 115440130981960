// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import PropTypes from "prop-types";
// material
import { styled } from "@mui/material/styles";
import { Card, Typography, Box, alpha } from "@mui/material";
import Label from "blocks/atoms/uicomponents/Label";
import { SignupButton, RenderBulletedHeadlineList } from "blocks/atoms";
import RenderSuitePriceList from "./RenderSuitePriceList";
import Image from "next/image";

const RootStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  height: "100%",
  flexGrow: 1,

  display: "flex",
  position: "relative",
  alignItems: "center",
  flexDirection: "column",
  padding: theme.spacing(3),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(4),
  },
}));
const StyledRibbon = styled(Box)(({ theme }) => ({
  position: "absolute",
  // inset: "0 auto auto 0", //for left
  background: theme.palette.secondary.main,
  // transformOrigin: "100% 0", //for left
  // transform: "translate(-29.3%) rotate(-45deg)", //for left
  boxShadow: `0 0 0 999px ${theme.palette.secondary.main}`,
  color: theme.palette.secondary.contrastText,
  clipPath: "inset(0 -100%)",
  inset: " 0 0 auto auto" /* top and right equal to 0 */,
  transformOrigin: "0 0" /* OR top left */,
  transform: "translate(29.3%) rotate(45deg)",
}));

// ----------------------------------------------------------------------

PricingPlanCard.propTypes = {
  index: PropTypes.number,
  card: PropTypes.object,
};

export default function PricingPlanCard({ card, index }) {
  const {
    subscription,
    icon,
    price,
    lists = [],
    labelAction = "Sign up",
    credits = null,
    recommended = false,
    specialSuiteList,
  } = card;

  const SuiteIcon = ({ Icon }) => {
    if (typeof Icon === "string")
      return (
        <Image
          fill
          alt={subscription}
          src={Icon}
          style={{ padding: 5 }}
          sizes="100vw"
        />
      );
    else return Icon;
  };
  return (
    <RootStyle
      sx={{
        maxWidth: {
          xs: "100%",
          sm: 400,
          md: "min(25vw, 450px)",
        },
      }}
    >
      <Box
        sx={{ width: 50, height: 50, position: "absolute", top: 10, left: 10 }}
      >
        <SuiteIcon Icon={icon} />
      </Box>

      {recommended && (
        <StyledRibbon>
          {/* <Label
          variant="ghost"
          color="primary"
          sx={{
            top: 16,
            right: 16,
            position: "absolute",
            minHeight: 30,
            fontWeight: "bold",
          }}
        > */}
          Popular
          {/* </Label> */}
        </StyledRibbon>
      )}
      <Typography
        variant="h6"
        sx={{ color: "text.primary", textTransform: "uppercase" }}
      >
        {subscription}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
        <Typography variant="h1" sx={{ color: "text.secondary" }}>
          $
        </Typography>

        <Typography
          variant="h1"
          color="primary.darker"
          sx={{ mx: 2, fontSize: 48 }}
        >
          {price}
        </Typography>

        <Typography
          gutterBottom
          component="span"
          variant="caption"
          sx={{
            alignSelf: "flex-end",
            color: "text.secondary",
          }}
        >
          per month
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: 50,
        }}
      >
        {credits ? (
          <Label
            variant="filled"
            sx={{
              minHeight: 30,
              fontWeight: "bold",
              p: 2,
              bgcolor: (theme) => alpha(theme.palette.secondary.lighter, 0.9),
            }}
          >
            {credits}
          </Label>
        ) : null}
      </Box>

      <SignupButton
        label={labelAction}
        routeToPush={
          subscription !== "Free" &&
          `${process.env.ROOT_URL}/user/manage?choice=addsuite`
        }
        sx={{ my: 2, bgcolor: "primary.dark" }}
      />

      <RenderBulletedHeadlineList
        title=""
        listItems={lists.map((item) => item.text)}
        sx={{ width: "100%" }}
      />
      {/*specialSuiteList ? (
        <Box
          sx={{
            my: 0,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <RenderSuitePriceList
            title={"Suite Customization (Optional)"}
            listItems={specialSuiteList}
          />
        </Box>
        ) : null*/}
      {/*<Button
        to={PATH_DASHBOARD.root}
        href={PATH_DASHBOARD.root}
        fullWidth
        size="large"
        variant="contained"
        component={Link}
      >
        {labelAction}
      </Button>*/}
    </RootStyle>
  );
}
