// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { Box, Grid, Container, Typography, useMediaQuery } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import Image from "next/image";

import { MotionInView, varFade } from "blocks/atoms/uieffects/animate";
import { useIntl } from "react-intl";
import MuxPlayer from "@mux/mux-player-react";

const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(7),
  [theme.breakpoints.up("md")]: {
    paddingBottom: theme.spacing(7),
  },
}));

export default function LandingIntroduction() {
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Box sx={{ mb: { xs: 2, md: 3 } }}>
          <MuxPlayer
            streamType="on-demand"
            playbackId="NdEwJp00DdpgKfSVZ7lYitr01Of7liuxtzU02UCj6Jiuek"
            metadata={{
              video_id: "video-id-trrystlandingpage",
              video_title: "Trryst Landing PAge Video",
              viewer_user_id: "user-id-007",
            }}
          />
          <MotionInView variants={varFade().inUp}>
            <Typography
              component="p"
              variant="overline"
              sx={{ mb: 2, color: "text.secondary", textAlign: "center" }}
            >
              {`${t("introduction.byline")}`}
            </Typography>
            <Typography variant="h3" sx={{ textAlign: "center" }}>
              {`${t("introduction.headline")}`}
            </Typography>
          </MotionInView>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MotionInView variants={varFade().inLeft}>
                <Image
                  src="/static/images/ConceptIntroductionPicture.png"
                  alt="Trryst Concept"
                  width={500}
                  height={500}
                  style={{
                    maxWidth: "100%",
                    height: "auto"
                  }} />
              </MotionInView>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <MotionInView variants={varFade().inRight}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ m: 2, textAlign: "center" }} variant="h5">
                    Communicate. Collaborate. Securely share.
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{ m: 2, textAlign: "center", color: "text.secondary" }}
                  >
                    Bringing together your executive stakeholders together for
                    important conversations should not be a hassle. You do not
                    need to need to share files over dropbox, send emails over
                    public networks, chat over whatsapp and share Zoom videocall
                    links for something as regular as a Boardroom conversations.
                    But that is the reality for most organizations today.
                  </Typography>

                  <Typography
                    variant="h5"
                    color="primary"
                    sx={{ m: 3, textAlign: "center" }}
                  >
                    Break the boundaries with Trryst.
                  </Typography>
                </Box>
              </MotionInView>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </RootStyle>
  );
}
