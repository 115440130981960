// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import { Iconify } from "blocks/atoms";
import { useHandleRouterPush } from "@app21/core";
import ContactUsPanelBackground from "public/static/images/ContactUsBackgroundPattern.png";

export default function LandingRequestDemoPanel() {
  const { loadRoute } = useHandleRouterPush();
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        p: 5,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        backgroundImage: `url(${ContactUsPanelBackground})`,
        backgroundSize: "cover",

        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        maxWidth="md"
        sx={{ display: "flex", alignItems: "center", py: 5 }}
      >
        <Grid container direction={{ xs: "column", md: "row" }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h1" color="common.white">
              Get started for free
            </Typography>
            <Typography variant="subtitle1" color="common.white" sx={{ my: 3 }}>
              Questions? Want to see a live demo or receive a free trial?
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{ ml: 3 }}
              size="large"
              endIcon={
                <Iconify
                  icon={"ic:round-arrow-right-alt"}
                  width={24}
                  height={24}
                />
              }
              onClick={() =>
                loadRoute("GOTO-LINK", { hrefLink: "/home/contactus?ref=demo" })
              }
            >
              <Typography variant="h6">Contact Us</Typography>
            </Button>
          </Grid>
        </Grid>
      </Container>
      ;
    </Box>
  );
}
