// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { useRouter } from "next/router";
import { Close } from "@mui/icons-material";

const ContactCard = ({ title, content, action, color }) => {
  return (
    <Card
      sx={{
        width: "100%",
        height: 350,
        borderTop: 4,
        borderColor: color || "grey.500",
        maxWidth: 500,
      }}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{ color: "text.secondary" }}
      />
      <CardContent
        sx={{
          py: 3,
          flexGrow: 1,
          display: "flex",

          height: 220,
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
          {content}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 2,
          position: "absolute",
          bottom: 0,

          width: "100%",
        }}
      >
        {action}
      </CardActions>
    </Card>
  );
};

export default function LandingContactUs() {
  const router = useRouter();
  const { ref } = router.query;
  const [contactFormOpen, setContactFormOpen] = React.useState(false);
  const handleContactFormOpen = () => setContactFormOpen(true);
  const handleContactFormClose = () => setContactFormOpen(false);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Container maxWidth="lg">
      <Box sx={{ p: 2 }}>
        <Typography variant="h1" sx={{ mt: 5, mb: 3, textAlign: "center" }}>
          We love to hear from you.
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Whether you want to know more about our features or want a free trial
          or have some ideas to share with us or if you are press, whatever be
          the context, we would love to hear from you.
        </Typography>
      </Box>

      <Box sx={{ my: 5 }}>
        <Grid container spacing={3} align="center">
          <Grid item xs={12} md={4}>
            <ContactCard
              title="Request a Callback"
              content="Please click the button below and schedule a suitable slot and we will be in touch with you at the earliest. Alternately, you can just drop us a note with your contact details at support @ trryst.com ."
              action={
                <Button
                  sx={{ m: 2 }}
                  variant="outlined"
                  size="large"
                  onClick={() =>
                    router.push("https://calendly.com/trryst/15min")
                  }
                >
                  Get in Touch
                </Button>
              }
              color="primary.main"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ContactCard
              title="Support Needed / Feedback"
              content="Please give as much information as possible (screenshots, if any, will be valuable!). Drop us a note at support @ trryst.com with the Info"
              action={
                <Button
                  sx={{ m: 2 }}
                  color="secondary"
                  variant="outlined"
                  size="large"
                  href="mailto:support@trryst.com"
                >
                  Let us Know
                </Button>
              }
              color="secondary.main"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ContactCard
              title="Press / Any other Enquiry"
              content="We would love to hear from you. You can fill the form by clicking the button below or give us shout at support @ trryst.com"
              action={
                <Button
                  sx={{ m: 2 }}
                  color="success"
                  variant="outlined"
                  size="large"
                  onClick={handleContactFormOpen}
                >
                  How can we help?
                </Button>
              }
              color="success.main"
            />
          </Grid>
        </Grid>
      </Box>
      <Dialog
        fullScreen
        open={contactFormOpen}
        onClose={handleContactFormClose}
        // TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleContactFormClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <iframe
          style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}
          src={`https://forms.zohopublic.eu/csuiteltd/form/ContactUs/formperma/1ED0zTZ9KNmEUiBIsOKwSpfaHA_kOQDM-fFvTjZkRhk`}
          width="100%"
          height="100%"
        />
      </Dialog>
    </Container>
  );
}
